import React, { Component } from "react";
import {
    Container,
    Box,
    CardContent,
    Button,
    InputLabel,
    Typography,
    InputAdornment,
    IconButton,
    // Customizable Area Start
    TableContainer,
    TableBody,
    Table,
    TableCell,
    TableRow,
    TableHead,
    Paper,
    MenuItem,
    Select,
    Grid,
    Chip,
    TextField,
    FormLabel,
    Radio,
    RadioGroup,
    FormControlLabel,
    Divider,
    Dialog,
    DialogContent,
    DialogActions,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Form } from "formik";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const { add_green, deleteBtn, alert } = require("./assets");
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const tableHeading = [
    "VENDOR",
    "SUBCONTRACTED",
    "PRODUCT VARIANT",
    "CURRENCY",
    "QTY",
    "UoM",
    "PRICE (in units)",
    "DELIVERY TIME LEAD",
];

interface ChipDropDownProps {
    name: string,
    options: { [name: string]: string }[],
    label: string,
    isRequired: boolean,
    values: any,
    handleBlur: any,
    handleChange: any,
    setFieldValue: any,
    placeholderValue: string
    touched:any,
    errors:any,
}

interface PurchasesTabProps {
    getInitialValues: () => {
        vendor_tax: string | string [],
        control_policy: boolean,
        purchase_description: string,
    }
    handleSave: () => Promise<void>;
    errors: any;
    touched: any;
    handleChange: () => {};
    values: any;
    handleBlur: () => {};
}

// Customizable Area End

const theme = createTheme({
    palette: {
        primary: {
            main: "#2A6395",
            contrastText: "#fff"
        },
        secondary:{
            main: "#2A6395",
        }
    },
});

const optionsList = [
    {
        id: "1",
        title: "Tax 10%",
        value: "Tax 10%",
    },
    {
        id: "2",
        title: "Tax 20%",
        value: "Tax 20%",
    },
    {
        id: "3",
        title: "Tax 30%",
        value: "Tax 30%",
    },
    {
        id: "4",
        title: "Tax 30%",
        value: "Tax 30%",
    },
];


// Customizable Area End

import ItemavailabilityController, { Props } from "./ItemavailabilityController";

export default class PurchaseTab extends React.Component<any> {
    constructor(props: PurchasesTabProps) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    autocompleteDropDrop = (
        { name, options, label, handleBlur, setFieldValue, placeholderValue, values ,touched ,errors}
            : ChipDropDownProps) => {
        const isEmpty = values?.[name] && values?.[name].length > 0;
        return (
            <div className="form-group form-check" style={webStyle.formGroupContainer}>
                <Typography style={webStyle.pageHeading as React.CSSProperties}>
                    {label}
                </Typography>
                <div style={{ padding: "10px 0px" }}>

                <Autocomplete
                    data-test-id={"autoComplete"}
                    style={{ width: "90%" }}
                    disableClearable
                    multiple
                    value={values?.[name]}
                    id="tags-outlined"
                    options={options}
                    renderOption={(option) => (
                        <Typography style={{ fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}>{option.title}</Typography>
                    )}
                    getOptionLabel={(option) => option.title}
                    PaperComponent={({ children }) => (
                        <Paper style={{ boxShadow: "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) ", marginTop: 2, }}>{children}</Paper>
                    )}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip
                                {...getTagProps({ index })}
                                deleteIcon={<CloseIcon style={{ color: "black", height: "17px" }} />}
                                label={option.title}/>
                        ))
                    }
                    popupIcon={
                    <ExpandMoreIcon />
                    }
                    onChange={(_, newValue) => { setFieldValue(name, newValue); }}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            onBlur={handleBlur}
                            variant="outlined"
                            name={name}
                            error={touched[name] && Boolean(errors[name])}
                            placeholder={isEmpty ? "" : placeholderValue}
                            className={
                                'form-check-input' + (errors[name] && touched[name] ? ' is-invalid' : '')
                            }
                        />
                    )}
                />
                </div>
            </div>
        );
    }

    // Customizable Area End


    render() {
        // Customizable Area Start
        return (
            <ThemeProvider theme={theme}>
                <Box sx={webStyle.PurchaseFormContainer} className="parent-div">
                    <style>
                        {`
                            .MuiInputBase-input{
                                border: 0px !important;
                            }
                            .radioBtn:hover {
                                background-color: rgba(0, 42, 87, 0.04) !important;
                            }
                            .radioBtn:focus {
                                background-color: rgba(0, 42, 87, 0.04) !important;
                            }
                            .vendorNameCell {
                                & .MuiInputBase-root {
                                    outline: none;
                                }
                            }
                            .MuiTableCell-alignLeft{
                                padding:8px;
                            }
                            .tablecontainer::-webkit-scrollbar {
                                display:none;
                            }
                
                            .MuiSelect-select.MuiSelect-select{
                                padding-right : 40px;
                            }
                            .MuiTableCell-alignLeft {
                                padding:8px;
                            }
                            .tableContainer{
                                & .MuiBox-root{
                                    padding: 0 !important;
                                }
                            }    
                            .MuiFormControl-fullWidth{
                                width: 90%;
                            }
                            .MuiChip-root {
                                background-color:#e0e0e0 !important;
                                color:#000 !important;
                            }
                            .MuiPaper-elevation1 {
                                box-shadow: none !important;
                            }
                            .MuiPaper-rounded {
                                border-radius: 0px !important;
                            }
                        `}
                    </style>
                    <Form translate={undefined}>
                        <Box style={{ padding: "0px !important", width: "100%" }} className="tableContainer">
                            <TableContainer component={Paper} className="tableContainer">
                                <Table aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            {tableHeading.map((item, index) => (
                                                <TableCell key={index} style={{ textTransform: "uppercase" }}>
                                                    <b style={{ whiteSpace: "nowrap", width: "100%" }}>{item}</b>
                                                </TableCell>
                                            ))}
                                            <TableCell align="left">
                                                <Button data-test-id="addRowBtn" onClick={this.props.handleAddRow}>
                                                    <img style={{ backgroundColor: "green" }} src={add_green} alt="addBtn" />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.props.vendorsListTableData?.map((vendor:any, rowIndex:number) => (
                                            <TableRow key={rowIndex}>
                                                <TableCell className="vendorNameCell" align="left">
                                                        <Select
                                                            data-test-id="vendorName"
                                                            labelId="vendorName"
                                                            displayEmpty
                                                            disableUnderline
                                                            value={vendor.vendor_name}
                                                            onChange={(event) => 
                                                                this.props.handleVendorsListResponse(event, rowIndex)
                                                            }
                                                            fullWidth
                                                            className={`width-100 ${!this.props.vendorsListTableData && 'select-default-color'}`}
                                                            IconComponent={ExpandMoreIcon}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                        marginTop: 55,
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                        <MenuItem disabled value="" style={{ display: 'none', color: "grey" }}>
                                                            Select Vendor Name
                                                        </MenuItem>
                                                        {this.props.vendorNameList && this.props.vendorNameList.length && this.props.vendorNameList.map((vendor: any, index: number) => (
                                                            <MenuItem key={index} value={vendor.vendor_name} placeholder="Select Vendor Name">
                                                                {vendor.vendor_name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </TableCell>
                                                <TableCell align="left" >{vendor.subcontracted}</TableCell>
                                                <TableCell align="left">{vendor.product_variant}</TableCell>
                                                <TableCell align="left">{vendor.currency}</TableCell>
                                                <TableCell align="left">{vendor.qty}</TableCell>
                                                <TableCell align="left">{vendor.uom}</TableCell>
                                                <TableCell align="left">{vendor.price} OMR</TableCell>
                                                <TableCell align="left">{vendor.delivery_time_lead}</TableCell>
                                                <TableCell>
                                                    <Button data-test-id="deleteRowBtn" disabled={this.props.vendorsListTableData.length === 1} onClick={this.props.vendorsListTableData.length > 1 ?() => this.props.handleDeleteRow(rowIndex, vendor.vendor_id) : ()=>{}}>
                                                        <img src={deleteBtn} alt="deleteBtn" />
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>

                        <Divider style={{ padding: "2px", marginTop: '3%', marginBottom: '2px' }} />                            

                        <Box className="form-div" style={{ width: "95%", paddingTop: "2%" , paddingBottom: "2%",paddingLeft: "1%" , paddingRight: "1%" }}>
                            <FormLabel component="legend" style={{ marginBottom: "15px" }}>Vendor Bills</FormLabel>
                            <Grid container spacing={4} justifyContent="space-between">
                                <Grid item sm={6} md={6} lg={6}>
                                    {this.autocompleteDropDrop({
                                        label: "Vendor Taxes",
                                        options: this.props.vendorTaxOption,
                                        name: "vendor_tax",
                                        isRequired: false,
                                        handleChange: this.props.handleAutocompleteValueChange,
                                        values: this.props.values,
                                        handleBlur: this.props.handleBlur,
                                        setFieldValue: this.props.setFieldValue,
                                        placeholderValue: "Select Vendor Taxes",
                                        touched:this.props.touched,
                                        errors:this.props.errors,
                                    })}
                                </Grid>
                            </Grid>

                            <Grid item sm={6} md={6} lg={6} style={{
                                width: "100%",
                            }}>
                                <Box style={{ display: "flex", marginTop: "5%", marginBottom: "5%" }} className={
                                    'form-control'
                                }>
                                    <Typography style={webStyle.tracking as React.CSSProperties}>
                                        {"Control Policy"}
                                        {<span style={{ color: "red" }}>*</span>}
                                    </Typography>
                                    <Divider variant="middle" orientation="vertical" flexItem />
                                    <Box>
                                        <RadioGroup data-test-id="radioBtn" aria-label="control_policy" name="control_policy" value={this.props.values.control_policy}
                                            onChange={(changeEvent, newValue) => { this.props.setFieldValue("control_policy", newValue) }}>
                                            <FormControlLabel value="On received quantities"
                                                control={
                                                    <Radio
                                                        className="radioBtn"
                                                        disableRipple
                                                        disableFocusRipple
                                                        disableTouchRipple
                                                        color="secondary"
                                                        size='small'
                                                    />
                                                }
                                                label={
                                                    <Typography
                                                        style={this.props.values.control_policy === "On received quantities" ? webStyle.radioChecked : webStyle.radioUnchecked}>
                                                        On received quantities
                                                    </Typography>
                                                }
                                            />
                                            <FormControlLabel value="On ordered quantities"
                                                control={
                                                    <Radio
                                                        className="radioBtn"
                                                        disableRipple
                                                        disableFocusRipple
                                                        disableTouchRipple
                                                        color="secondary"
                                                        size='small'
                                                    />
                                                }
                                                label={
                                                    <Typography
                                                        style={this.props.values.control_policy === "On ordered quantities" ? webStyle.radioChecked : webStyle.radioUnchecked}>
                                                        On ordered quantities
                                                    </Typography>
                                                }
                                            />
                                        </RadioGroup>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid container spacing={4}>
                                <Grid item sm={12} md={12} lg={12}>
                                    <div
                                        className="form-group"
                                        style={webStyle.formGroupContainer}>
                                        <Typography
                                            style={webStyle.pageHeading as React.CSSProperties}>
                                            {"Purchase Description"}
                                        </Typography>
                                        <div style={webStyle.errorContainer as React.CSSProperties}>
                                            <TextField
                                                variant="outlined"
                                                data-test-id="purchaseDescription"
                                                value={this.props.values?.["purchase_description"]}
                                                type="text"
                                                className='form-control'
                                                placeholder={"This note added to purchase description..."}
                                                name={"purchase_description"}
                                                style={{ width: "100%" }}
                                                onChange={this.props.handleChange}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <Typography style={{ color: "black" }}>EN</Typography>
                                                    </InputAdornment>,
                                                }}
                                                inputProps={{ maxLength: 265,}}
                                            />
                                        </div>
                                    </div>
                                    <Typography 
                                    style={webStyle.notesBottomTextGrey as React.CSSProperties}>
                                    max 265 characters*
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={webStyle.actionBtnStyle}>
                            <Button
                                data-test-id="discardBtn"
                                variant="contained"
                                onClick={() => {this.props.handleDiscardImage();this.props.resetForm({ values: this.props.getInitialValues() });}}
                                style={{ backgroundColor: "#fff" }}
                            >Discard</Button>
                            <Button
                                data-test-id="saveBtn"
                                style={webStyle.saveBtn}
                                type="submit"
                                onClick={() => { 
                                    this.props.validateInput(this.props.productName);
                                    this.props.handleSave(this.props.values);
                                }}
                            >Save</Button>
                        </Box>
                        <Dialog
                            maxWidth={"sm"}
                            data-test-id="deleteDialog"
                            open={this.props.deleteDialog}
                            onClose={this.props.handleDialogCancel}
                        >
                            <DialogContent>
                                <Typography>
                                    Are you sure you want to delete ?
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={this.props.handleDialogCancel}
                                    data-test-id="dialogCancelBtn"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={this.props.onDeletePurchase}
                                    data-test-id="dialogDeleteBtn"
                                >
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Form>
                </Box>
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    PurchaseFormContainer: {
        display: "block",
        paddingBlock: 8,
    },
    pageHeading: {
        textWrap: "nowrap !important"
    },
    formGroupContainer: {
        width: "100%",
        alignItems: "baseline",
    },
    radioUnchecked: {
        color: "#000",
    },
    radioChecked: {
        fontWeight: 600
    },
    tracking: {
        marginTop: "4px",
        textWrap: "nowrap !important",
    },
    actionBtnStyle: {
        display: "flex",
        gap: 8,
        float: "right",
        marginBlock: "40px",
    },
    saveBtn: {
        color: "#ffffff",
        backgroundColor: "rgba(9, 65, 115, 0.77)",
    },
    errorContainer: {
        padding: "10px 0",
        flexDirection: "column",
        flex: 1,
        display: "flex",
    },
    errorBox: {
        alignItems: 'center',
        display: 'flex',
    },
    alertIcon: {
        position: "absolute",
        width: "10px",
    },
    errorTextMsg: {
        color: 'red',
        marginLeft: "15px"
    },
    notesBottomTextGrey: {
        textAlign: "right",
        color: "grey",
        fontSize: "12px",
    },
};
// Customizable Area End
